export default {
  computed: {
    /**
     * Get the configuration object fetched from the api holding information
     * like countries, paymentTypes, etc.
     * @returns {any}
     */
    configs() {
      return this.$store.state.contract.configs;
    },
    /**
     * Get the available payment methods from the store.
     * Payment methods are set on page load when triggering the action 'getConfigs'.
     */
    paymentMethods() {
      return this.configs ? this.configs.paymentTypes : null;
    },
    /**
     * Check whether payment fields should be displayed to the user
     * depending on the selected payment method.
     * If the systemId of the selected payment method is equal to 1 (direct debit / Lastschrift)
     * return true. Else return false.
     * @returns {boolean} The boolean indicating whether payment fields should be displayed.
     */
    paymentFieldsRequired() {
      // Find the corresponding object by id
      const methodObj = this.paymentMethods.find((method) => method.id === this.accounting.paymentMethod);
      return methodObj && methodObj.systemId === 1;
    },
    /**
     * Get all iban lengths in an array from countries.
     * @returns {*}
     */
    ibanLengths() {
      return this.configs.countries.map((country) => country.ibanLength);
    },
    /**
     * Get the minimum iban length.
     * @returns {number}
     */
    minIbanLen() {
      return Math.min(...this.ibanLengths);
    },
    /**
     * Get the maximum iban length.
     * @returns {number}
     */
    maxIbanLen() {
      return Math.max(...this.ibanLengths);
    },
  },
};
