<template>
  <SafeForm @submit.prevent="onContinue">
    <div>
      <!-- Due Date -->
      <SelectField
        v-model="accounting.dueDate"
        :label="$t('paymentDueDate')"
        floating
        required
      >
        <option value="keyDate">{{ $t('keyDate') }}</option>
        <template v-if="selectedContractRenewal.accountingCycleUnit === 1">
          <option value="firstOfMonth">{{ $t('firstOfMonth') }}</option>
          <option value="fifteenthOfMonth">{{ $t('fifteenthOfMonth') }}</option>
        </template>
      </SelectField>
      <!-- Payment Method -->
      <SelectField
        v-model.number="accounting.paymentMethod"
        type="number"
        :label="$t('paymentMethod')"
        floating
        required
      >
        <option v-for="method in paymentMethods" :key="method.id" :value="method.id">
          {{ method.name }}
        </option>
      </SelectField>
      <!-- Banking details (only render when certain payment methods are selected) -->
      <template v-if="paymentFieldsRequired">
        <!-- Account Holder -->
        <TextField
          v-model="accounting.accountHolderBank"
          :label="$t('accountHolder')"
          floating
          :required="paymentFieldsRequired"
        />
        <!-- IBAN -->
        <TextField
          ref="ibanInput"
          v-model="accounting.ibanBank"
          id="field_iban"
          autocomplete="off"
          floating
          auto-unmask
          :label="$t('iban')"
          :required="paymentFieldsRequired"
          :custom-error="validIban === false"
          :custom-error-msg="$t('ibanIsNotValid')"
          :mask="mask"
          @input="onIbanInput"
          @paste.native="onIbanPaste"
        />
        <template v-if="validIban === false">
          <Button
            text
            class="mb-4 -ml-4 -mt-4"
            @click="continueWithCash"
          >
            {{ $t('continueWithCash') }}
          </Button>
        </template>
        <!-- BIC -->
        <TextField
          ref="bicField"
          v-model="accounting.bicBank"
          :label="$t('bic')"
          id="field_bic"
          autocomplete="off"
          floating
          :required="paymentFieldsRequired"
          :minlength="8"
          :maxlength="11"
        />
        <!-- Name of the bank -->
        <TextField
          ref="nameField"
          v-model="accounting.nameBank"
          :label="$t('bank')"
          id="field_bank"
          autocomplete="off"
          floating
          :required="paymentFieldsRequired"
        />
      </template>
    </div>
    <!-- Button controls -->
    <div class="flex flex-shrink w-full mt-12 pb-12">
      <!-- Back button -->
      <Button class="mr-2 w-full" to="customer">
        {{ $t('back') }}
      </Button>
      <!-- Continue button -->
      <Button
        class="ml-2 w-full"
        :class="{
          'pointer-events-none': disableContinue,
        }"
        primary
        type="submit"
      >
        {{ $t('continue') }}
      </Button>
    </div>
  </SafeForm>
</template>

<script>
import paymentHelper from '@/mixins/paymentHelper';

export default {
  data() {
    return {
      accounting: {
        dueDate: null,
        paymentMethod: null,
        accountHolderBank: null,
        ibanBank: null,
        bicBank: null,
        nameBank: null,
        country: null,
      },
      showAltAddress: false,
      validIban: null,
      pastedIban: null,
      mask: '',
    };
  },

  mixins: [paymentHelper],

  computed: {
    selectedContractRenewal() {
      return this.$store.state.contract.selectedContractRenewal;
    },
    /**
     * Get all countries from the config.
     * A country contains information about the iban format its start token and more.
     */
    countries() {
      return this.$store.state.contract.configs.countries;
    },
    /**
     * This computed property is used for helping to determine whether the continue
     * button should be disabled or not. By returning the min length of the iban depending
     * on the current user input it's possible to ensure the user entered enough characters.
     */
    currentMinIbanLen() {
      const startToken = this.getStartToken(this.accounting.ibanBank);
      if (startToken) {
        const country = this.getMatchingCountry(startToken);
        if (country) return country.ibanLength;
      }
      return null;
    },
    /**
     * Return true when the continue button should be disabled.
     * This is true when either the iban is not valid or the current iban user input does not
     * have enough characters.
     * If other payment methods are selected the continue button should not be disabled.
     */
    disableContinue() {
      if (this.paymentFieldsRequired) {
        const ibanIsValid = this.validIban === true;
        const ibanHasMinLen = this.accounting.ibanBank ? this.accounting.ibanBank.length >= this.currentMinIbanLen : false;
        return !ibanIsValid || !ibanHasMinLen;
      }
      return false;
    },
  },

  watch: {
    accounting: {
      deep: true,
      handler() {
        this.$store.state.contract.newContract.accounting = {
          ...this.$store.state.contract.newContract.accounting,
          ...this.accounting,
        };
      },
    },
  },

  created() {
    // Set the current component's accounting data to the vuex state.
    this.accounting = this.$store.state.contract.newContract.accounting;
    const { customer } = this.$store.state.contract.newContract;
    if (customer.firstname && customer.lastname && !this.accounting.accountHolderBank) {
      this.accounting.accountHolderBank = `${customer.firstname} ${customer.lastname}`;
    }
    if (customer.country) {
      this.accounting.country = customer.country;
    }
    // Get the iban and check whether an iban is already present. This might be the case
    // when the user returns to this view from summary.
    const iban = this.accounting.ibanBank;
    const ibanHasMinLen = iban && this.currentMinIbanLen && (iban.length >= this.currentMinIbanLen);
    if (ibanHasMinLen) {
      this.checkIban(iban);
    } else {
      this.accounting.bicBank = null;
      this.accounting.nameBank = null;
    }
  },

  methods: {
    onContinue() {
      this.$store.commit('updateContractForm', {
        accounting: this.accounting,
      });
      this.$router.push('summary');
    },
    /**
     * Get the start token from the users input into the iban text field.
     */
    getStartToken(value) {
      return value && value.length >= 2 ? value.substr(0, 2) : null;
    },
    /**
     * Get the matching country by searching through all countries with the
     * start token provided by the user in the iban text field.
     */
    getMatchingCountry(startToken) {
      if (startToken) {
        return this.countries.find((country) => country.ibanStartToken === startToken);
      }
      return null;
    },
    /**
     * Update the mask and the min length of the iban as the user types.
     * After the first two characters are typed try to identify the country by the information
     * fetched from teh api and adapt the mask of the input field accordingly.
     * When an iban's min length is reached, send a request to the api and check if the iban is
     * valid and whether there is any additional information to retrieve from the iban like bank
     * name or BIC.
     * @param value The user's input.
     */
    onIbanInput(value) {
      this.buildMask(value); // Build mask. Note: Is called the second time here if user pastes iban.
      const ibanHasMinLen = value && this.currentMinIbanLen && (value.length >= this.currentMinIbanLen);
      if (ibanHasMinLen) {
        // User has entered a potentially valid IBAN for the specified country.
        // Check iban validity.
        this.checkIban(value);
      } else {
        // IBAN still incomplete, user has to enter more characters
        this.accounting.bicBank = null;
        this.accounting.nameBank = null;
      }
    },
    /**
     * Set the pasted iban as the new iban when the user pastes an iban.
     */
    onIbanPaste(evt) {
      this.accounting.ibanBank = null; // Set iban null to prevent error when delete whole text field
      this.pastedIban = evt.clipboardData.getData('text')
        .replace(/\s+/g, '');
      // Build mask first then trigger input event.
      // NOTE: Order is important! Otherwise if the pasted iban is longer than the current one,
      // some characters will be cut off.
      this.buildMask(this.pastedIban);
      this.onIbanInput(this.pastedIban);
    },
    /**
     * Get the mask associated with a specific iban start token provided by the user.
     * If the user has entered at least two characters so a country can be matched to the provided
     * two characters resembling the iban start token, build the mask by grouping characters in
     * groups of four, each group separated by a white space.
     * Else if there is a start token provided by the user but no match was found, then only allow
     * entering two characters so the user can correct his input.
     * Else return the German iban format by default.
     */
    buildMask(value) {
      const startToken = this.getStartToken(value);
      const matchingCountry = this.getMatchingCountry(startToken);
      if (matchingCountry) {
        let mask = 'AA';
        const minLen = matchingCountry.ibanLength;
        // Build mask
        const START_TOKEN_LEN = 2; // Length of the characters of the iban determining the country code (e.g. 'DE')
        for (let i = 0; i < minLen - START_TOKEN_LEN; i += 1) {
          if (i === START_TOKEN_LEN) {
            mask += ' '; // Add space after country code and first two digits
          } else if ((i + START_TOKEN_LEN) % 4 === 0 && i !== minLen - START_TOKEN_LEN) {
            mask += ' ';
          }
          mask += '(A|9)';
        }
        this.mask = mask;
      } else if (startToken) {
        // Start token entered but country not recognized.
        // Only allow correcting the start token until valid.
        this.mask = 'AA';
      } else {
        // If less than two characters were entered return the German Iban format by default.
        this.mask = 'AA99 9999 9999 9999 9999 99';
      }
    },
    /**
     * Check whether a given iban is valid.
     * If iban is valid, update the prop validIban and set the bicBank and the nameBank accordingly.
     * @param value
     * @returns {Promise<void>}
     */
    async checkIban(value) {
      this.$store.dispatch('checkIban', value)
        .then((resp) => {
          this.validIban = resp.data.validIban;
          if (resp.data.validIban) {
            this.accounting.bicBank = resp.data.bicBank;
            this.accounting.nameBank = resp.data.nameBank;
            this.$nextTick(() => {
              this.$refs.bicField.checkValidity();
              this.$refs.nameField.checkValidity();
            });
          }
        });
    },
    continueWithCash() {
      this.accounting.accountHolderBank = null;
      this.accounting.ibanBank = null;
      this.accounting.bicBank = null;
      this.accounting.paymentMethod = 5; // cash / Bar
      this.validIban = true;
    },
  },
  updated() {
    if (this.pastedIban) {
      this.accounting.ibanBank = this.pastedIban;
      this.pastedIban = null;
    }
  },
};
</script>
